import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Textarea } from '@/components/ui/base/textarea'
import { areObjectsEqual } from '@/lib/search-params-utils'
import { Button } from '@/shared/ui/base/button'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

const formSchema = z.object({
    field: z.string(),
})

export function SearchFilterValuePreviewArrayString({ value }: { value: string[] }) {
    const length = value ? value.length : 0
    const are_urls = value.some((v) => v.includes('https://') || v.includes('http://') || v.includes('linkedin.com'))
    if (are_urls) {
        return (
            <p className="text-sm text-ellipsis hyphens-auto text-start max-w-80 text-nowrap overflow-hidden">
                {formatNumberWithComma(length)} urls
            </p>
        )
    }
    return (
        <p className="text-sm text-ellipsis hyphens-auto text-start max-w-80 text-nowrap overflow-hidden">{`${value ? value[0] : ''}${length > 1 ? ` + ${length - 1}` : ''}`}</p>
    )
}

export function SearchFilterFormArrayString({
    value,
    onChangeValue,
    onInteractOutside,
    closePopover,
}: {
    id: string
    value: string[]
    onChangeValue: (_value: string[] | undefined) => void
    onInteractOutside: boolean
    closePopover: () => void
}) {
    useEffect(() => {
        if (onInteractOutside === true) {
            onSubmit(form.getValues())
        }
    }, [onInteractOutside])

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            field: value ? value.join('\n') : '',
        },
    })

    function onSubmit(values: z.infer<typeof formSchema>) {
        const newValue = values.field
            .split('\n')
            .filter((v) => v !== '' && v !== ' ' && v !== '\n' && v !== '\r')
            .map((v) => v.trim())
        if (areObjectsEqual(newValue, value)) {
            closePopover()
            return
        }
        onChangeValue(newValue.length > 0 ? newValue : undefined)
    }

    return (
        <>
            <Form {...form}>
                <form className="grid max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="field"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Textarea
                                        autoFocus={true}
                                        className="min-h-[300px]"
                                        placeholder="keyword1&#10;keyword2&#10;keyword3"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit" size="sm">
                        Save
                    </Button>
                </form>
            </Form>
        </>
    )
}
