import { z } from 'zod'

export const searchTypeSchema = z.enum(['jobs', 'companies', 'technologies'])

export type SearchType = z.infer<typeof searchTypeSchema>

export const requestSchema = z.object({
    id: z.number(),
    name: z.string().nullable(),
    body: z.any(),
    type: searchTypeSchema,
    start_datetime: z.string(),
    url: z.string(),
    num_returned_companies: z.number().optional().nullable(),
    num_returned_jobs: z.number().optional().nullable(),
    user: z.object({
        id: z.number(),
        first_name: z.string(),
        last_name: z.string(),
        email: z.string(),
    }),
})

export type Request = z.infer<typeof requestSchema>
