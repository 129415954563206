import { getHTTPHeaders, getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL, sourceSessionParamsObject } from '@/lib/http-utils'
import { User, userSchema, UserWithTeam, userWithTeamSchema } from '@/schemas/entities/user'
import { z } from 'zod'
import { getSessionId } from './tracking.service'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/users'

export async function getMyProfile(): Promise<UserWithTeam> {
    const res = await fetch(RESOURCE_URL + '/me', { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return userWithTeamSchema.parse(await res.json())
}

export async function getAllActiveUsers(email_substring: string): Promise<User[]> {
    const url = constructURL(`${RESOURCE_URL}`, {
        ...sourceSessionParamsObject(),
        is_active: true,
        limit: 50,
        email_substring: email_substring,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(userSchema).parse(await res.json())
}

export type SignUpThroughMakeResponse = 'created' | 'email_already_exist' | 'email_not_allowed'

export async function signUpThroughMake(
    first_name: string,
    last_name: string,
    email: string
): Promise<SignUpThroughMakeResponse> {
    const body = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        session_id: getSessionId(),
    }
    const res = await fetch(import.meta.env.VITE_SIGNUP_WEBHOOK_MAKE, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    })
    return res.status === 201 ? 'created' : res.status === 409 ? 'email_not_allowed' : 'email_already_exist'
}

export async function getCheckoutSessionUrl(plan_id: string): Promise<string> {
    const url = constructURL(`${RESOURCE_URL}/me/get_checkout_session_url`, {
        ...sourceSessionParamsObject(),
        price_id: plan_id,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return (await res.text()).replaceAll('"', '')
}

export async function getBillingPortalSessionUrl(): Promise<string> {
    const url = constructURL(`${RESOURCE_URL}/me/billing_portal_session_url`, { ...sourceSessionParamsObject() })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return ((await res.json()) as { url: string }).url
}

export async function cancelSubscription(): Promise<boolean> {
    const url = constructURL(`${RESOURCE_URL}/me/cancel_subscription`, { ...sourceSessionParamsObject() })
    const res = await fetch(url, { method: 'PATCH', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return res.status === 200
}

export async function deleteUser(user_id: number): Promise<boolean> {
    const url = constructURL(`${RESOURCE_URL}/${user_id}`, { ...sourceSessionParamsObject() })
    const res = await fetch(url, { method: 'DELETE', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return res.status === 200
}
