'use client'

import { addDays, format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { DateRange } from 'react-day-picker'

import { Calendar } from '@/components/ui/base/calendar'
import { cn } from '@/lib/utils'
import { Button } from '@/shared/ui/base/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui/base/popover'
import { useState } from 'react'

const OPTIONS_DATE_RANGE = [
    {
        label: 'Today',
        value: { from: new Date(), to: new Date() },
    },
    {
        label: 'Last 7 days',
        value: { from: addDays(new Date(), -7), to: new Date() },
    },
    {
        label: 'Last 30 days',
        value: { from: addDays(new Date(), -30), to: new Date() },
    },
    {
        label: 'Last 60 days',
        value: { from: addDays(new Date(), -60), to: new Date() },
    },
    {
        label: 'Last 90 days',
        value: { from: addDays(new Date(), -90), to: new Date() },
    },
]

export function DatePickerWithRange({
    init_date,
    onChangeDate,
    className = '',
}: {
    init_date: DateRange | undefined
    onChangeDate: (_date: DateRange | undefined) => void
    className?: string
}) {
    const [date, setDate] = useState<DateRange | undefined>(init_date)

    const handleOnChangeDate = (date: DateRange | undefined) => {
        setDate(date)
        onChangeDate(date)
    }

    return (
        <div className={cn('grid gap-2', className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={'outline'}
                        className={cn(
                            'w-[250px] justify-start text-left font-normal',
                            !date && 'text-muted-foreground'
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                                </>
                            ) : (
                                format(date.from, 'LLL dd, y')
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <div className="flex flex-row">
                        <Calendar
                            initialFocus
                            mode="range"
                            defaultMonth={date?.from}
                            selected={date}
                            onSelect={handleOnChangeDate}
                            numberOfMonths={2}
                        />
                        <div className="flex flex-col justify-start gap-2 p-3">
                            {OPTIONS_DATE_RANGE.map((option) => (
                                <Button
                                    key={option.label}
                                    variant="ghost"
                                    className="justify-start"
                                    onClick={() => handleOnChangeDate(option.value)}
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    )
}
