import { ColumnDef } from '@tanstack/react-table'

import APICurlButton from '@/components/ui/table/actions/action-get-api-curl'
import { DataTableColumnHeader } from '@/components/ui/table/data-table-column-header'
import { extractPathFromTheirStackAPIURL } from '@/lib/http-utils'
import { Request } from '@/schemas/entities/recent-search.schema'
import { getCompaniesCURL } from '@/services/company.service'
import { getJobsCURL } from '@/services/job.service'
import { formatDateTimeStringToReadableDateTime } from '@/shared/utils/date-utils'

export const hiddenCompanyColumns = {}

export const columns: ColumnDef<Request>[] = [
    {
        accessorKey: 'start_datetime',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.start_datetime && (
                        <p className="w-48">{formatDateTimeStringToReadableDateTime(row.original.start_datetime)}</p>
                    )}
                </>
            )
        },
        enableSorting: false,
    },

    {
        accessorKey: 'url',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Endpoint" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.start_datetime && (
                        <a
                            href={`https://api.theirstack.com`}
                            target="_blank"
                            rel="noreferrer"
                            className=" hover:underline"
                        >
                            <span className="mr-2 text-green-500">POST</span>
                            {extractPathFromTheirStackAPIURL(row.original.url)}
                        </a>
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },

    {
        accessorKey: 'user',
        header: ({ column }) => <DataTableColumnHeader column={column} title="User" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && (
                        <div className="flex flex-col items-start gap-1">
                            <p>
                                {row.original.user.first_name} {row.original.user.last_name}
                            </p>
                            <p className="text-xs text-muted-foreground">{row.original.user.email}</p>
                        </div>
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },

    {
        accessorKey: 'credits_consumed',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Credits consumed" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.url.includes('jobs') ? (
                        <p>{row.original.num_returned_jobs || 0}</p>
                    ) : (
                        <p>{row.original.num_returned_companies || 0}</p>
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Summary" />,
        cell: ({ row }) => {
            return <>{row.original && <p className="text-xs text-muted-foreground max-w-80">{row.original.name}</p>}</>
        },
        enableHiding: true,
        enableSorting: false,
    },
    {
        accessorKey: 'request',
        header: ({ column }) => <DataTableColumnHeader column={column} title="" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original.body && (
                        <APICurlButton
                            className="hidden lg:block"
                            variant="outline"
                            buttonTitle="View request"
                            curl={getRequestCURL(row.original.body, row.original.url)}
                        />
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },
]

export const getRequestCURL = (body: any, url: string) => {
    const pagination = { pageIndex: body.page, pageSize: body.limit, offset: body.offset }
    if (url.includes('jobs')) {
        return getJobsCURL(body, pagination)
    }
    return getCompaniesCURL(body, pagination)
}
