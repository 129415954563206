import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL } from '@/lib/http-utils'
import { Pagination } from '@/schemas/entities/pagination.schema'
import { Request, SearchType, requestSchema } from '@/schemas/entities/recent-search.schema'
import { RequestSearchParams } from '@/schemas/request/request-search-params.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/requests/'

export async function getUniqueUIRequests(type: SearchType | undefined, page: number): Promise<Request[]> {
    const url = constructURL(RESOURCE_URL + 'unique-ui/', {
        types: type ? [type] : ['jobs', 'companies'],
        page: page,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(requestSchema).parse(((await res.json()) as { data: Request[] }).data)
}

export async function getRequests(params: RequestSearchParams, pagination: Pagination): Promise<Request[]> {
    const url = constructURL(RESOURCE_URL, {
        start_datetime: params.start_datetime?.toISOString(),
        end_datetime: params.end_datetime?.toISOString(),
        page: pagination.pageIndex,
        limit: pagination.pageSize,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(requestSchema).parse(((await res.json()) as { data: Request[] }).data)
}
