import { z } from 'zod'

export const planSchema = z.object({
    name: z.string(),
    credits: z.number(),
    api_credits: z.number(),
    api_credits_used_current_period: z.number(),
    interval: z.string(),
    interval_count: z.number(),
    current_period_start: z.string().nullable(),
    current_period_end: z.string().nullable(),
    status: z.enum([
        // from subscription
        'active',
        'canceled',
        'incomplete',
        'incomplete_expired',
        'past_due',
        'paused',
        'trialing',
        'unpaid',
        // from invoice
        'draft',
        'open',
        // "paid", -> active instead
        'uncollectible',
        'void',
    ]),
    hosted_invoice_url: z.string().nullable().optional(),
    cancel_at_period_end: z.boolean().nullable(),
    recurring: z.boolean(),
    paid_at: z.string().nullable(),
    created_at: z.string().nullable(),
})
export type Plan = z.infer<typeof planSchema>

export const userSchema = z.object({
    id: z.number(),
    first_name: z.string().nullable().optional(),
    last_name: z.string().nullable().optional(),
    email: z.string(),
    is_active: z.boolean(),
    is_superuser: z.boolean(),
    token: z.string(),
})

export type User = z.infer<typeof userSchema>

export const teamSchema = z.object({
    id: z.number(),
    name: z.string(),
    plans: z.array(planSchema),
    credits_used_current_period: z.number(),
    credits_left_current_period: z.number(),
    current_period_start: z.string(),
    current_period_end: z.string(),
    credits: z.number(),
    api_credits: z.number(),
    api_credits_used_current_period: z.number(),
    api_credits_left_current_period: z.number(),
})

export const userWithTeamSchema = userSchema.extend({
    team: teamSchema,
})

export type UserWithTeam = z.infer<typeof userWithTeamSchema>

export const getActivePlan = (user: UserWithTeam) => {
    return user.team?.plans?.sort((a, b) => b.credits - a.credits).find((plan) => plan.status === 'active')
}

export const isFreePlan = (user: UserWithTeam): boolean => {
    return (user.team?.plans?.length ?? 1) === 1
}

export const hasPaidSubscription = (user: UserWithTeam): boolean => {
    return user.team?.plans?.some((plan) => plan.recurring === true && plan.name != 'Free') ?? false
}

export const getActivePaidSubscription = (user: UserWithTeam): z.infer<typeof planSchema> | undefined => {
    return user.team?.plans?.find((plan) => plan.status === 'active' && plan.recurring === true && plan.name != 'Free')
}

export const isCancelationAvailable = (plan: Plan): boolean => {
    return (
        plan.status === 'active' && plan.recurring === true && plan.name != 'Free' && plan.cancel_at_period_end != true
    )
}

export const getTotalCredits = (user: UserWithTeam) => {
    return user.team?.credits || 0
}

export const hasIcompletePlans = (user: UserWithTeam) => {
    return getIncompletePlans(user).length > 0
}

export const getIncompletePlans = (user: UserWithTeam) => {
    return user.team?.plans?.filter((plan) => plan.status !== 'active') || []
}
