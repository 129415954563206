import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL } from '@/lib/http-utils'
import { CreditsConsumption, creditsConsumptionSchema } from '@/schemas/entities/credits-consumption.schema'
import { BillingProduct, billingProductSchema } from '@/shared/schemas/entity/billing-product.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/billing'

export async function getBillingProducts(): Promise<BillingProduct[]> {
    const res = await fetch(RESOURCE_URL + '/products')
    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    return z.array(billingProductSchema).parse(await res.json())
}

export async function getCreditsConsumption(start_datetime: Date, end_datetime: Date): Promise<CreditsConsumption[]> {
    const url = constructURL(import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/teams/api_credits_consumption', {
        start_datetime: start_datetime.toISOString(),
        end_datetime: end_datetime.toISOString(),
    })
    const res = await fetch(url, {
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    const data = await res.json()
    return z.array(creditsConsumptionSchema).parse(data)
}
